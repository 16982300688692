.container {
  width: 100%;
}
.main-heading {
  margin-top: 2.5rem;
}

.button-3d,
.button-3d-sm {
  font-family: "Space Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: 700;
  display: inline-block;
  width: auto;
  color: #ffffff;
  border-radius: 5px;
  border: solid 1px #f39c12;
  background: #e67e22;
  text-align: center;
  padding: 8px 0 7px;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  transition: all 0.1s;
  margin-bottom: 6px;
  width: 85px;

  -webkit-box-shadow: 0px 6px 0px #d35400;
  -moz-box-shadow: 0px 6px 0px #d35400;
  box-shadow: 0px 6px 0px #d35400;

  cursor: pointer;
}

.generate-btn {
  align-self: flex-end;
}

.generate-btn {
  align-self: flex-end;
}

.button-3d.generate {
  border: solid 1px #1ac292;
  background: #00a877;
  -webkit-box-shadow: 0px 5px 0px #018660;
  -moz-box-shadow: 0px 5px 0px #018660;
  box-shadow: 0px 5px 0px #018660;
  margin-bottom: 6px;
}
.button-container {
  margin-top: 1rem;
}
.button-3d.generate:active {
  -webkit-box-shadow: 0px 2px 0px #018660;
  -moz-box-shadow: 0px 2px 0px #018660;
  box-shadow: 0px 2px 0px #018660;
  position: relative;
  top: 4px;
}

.button-3d-sm {
  -webkit-box-shadow: 0px 5px 0px #d35400;
  -moz-box-shadow: 0px 5px 0px #d35400;
  box-shadow: 0px 5px 0px #d35400;
  margin-bottom: 15px;
}
.button-container {
  margin-top: 1rem;
}
.button-3d:active,
.button-3d-sm:active {
  -webkit-box-shadow: 0px 2px 0px #d35400;
  -moz-box-shadow: 0px 2px 0px #d35400;
  box-shadow: 0px 2px 0px #d35400;
  position: relative;
  top: 4px;
}
.button-3d:focus,
.button-3d-sm:focus {
  outline: 0;
}

.mb-16 {
  margin-bottom: 16px;
}
.mb-32 {
  margin-bottom: 32px;
}
.d-flex {
  display: flex;
}
.fd-column {
  flex-direction: column;
}
.j-center {
  justify-content: center;
}
.text-area-container {
  position: relative;
}
.right-btn-container {
  margin-left: 1rem;
}
.left-btn-container {
  margin-right: 1rem;
  justify-content: space-between;
  width: 85px;
}
.empty-container-left {
  width: 7rem;
}
.empty-container-right {
  width: 8.5rem;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.ai-center {
  align-items: center;
}
.jc-between {
  justify-content: space-between;
}
.p-relative {
  position: relative;
}
.auto-container {
  position: absolute;
  left: 100%;
  margin-bottom: 14px;
  margin-left: 14px;
  width: 100%;
}
.auto-text {
  margin-left: 12px;
  user-select: none;
  vertical-align: baseline;
  font-size: 16px;
  vertical-align: text-top;
}
.auto-label {
  display: block;
  padding-left: 27px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.auto-label .auto-validate {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 28px;
  width: 29px;
  background-color: #222426;
  border-radius: 3px;
}
.checkmark-disabled {
  opacity: 0.5;
}

.light-mode .checkmark {
  background-color: #fdf6e3;
  box-shadow: 0 0 0 0.4px #eee8d5;
}
.dracula .checkmark {
  background-color: #21222c;
}

.synth-wave .checkmark {
  background: #2b213a;
}
.horizon .checkmark {
  background-color: #fce5df;
  box-shadow: 0 0 0 0.4px #ffd5cb;
}
.tomorrow .checkmark {
  background-color: #f1f1f1;
  box-shadow: 0 0 0 0.4px #d4d4d4;
}

/* On mouse-over, add a grey background color */
.auto-label:hover .auto-validate ~ .checkmark {
  background-color: #222426;
}

.auto-label .auto-validate:checked ~ .checkmark {
  background-color: #222426;
}
.light-mode .auto-label:hover .auto-validate ~ .checkmark {
  background-color: #fdf6e3;
}
.light-mode .auto-label .auto-validate:checked ~ .checkmark {
  background-color: #fdf6e3;
}
.dracula .auto-label:hover .auto-validate ~ .checkmark {
  background-color: #21222c;
}
.dracula .auto-label .auto-validate:checked ~ .checkmark {
  background-color: #21222c;
}
.synth-wave .auto-label:hover .auto-validate ~ .checkmark {
  background-color: #2b213a;
}
.synth-wave .auto-label .auto-validate:checked ~ .checkmark {
  background-color: #2b213a;
}
.horizon .auto-label:hover .auto-validate ~ .checkmark {
  background-color: #fce5df;
  box-shadow: 0 0 0 0.4px #ffd5cb;
}
.horizon .auto-label .auto-validate:checked ~ .checkmark {
  background-color: #fce5df;
  box-shadow: 0 0 0 0.4px #ffd5cb;
}
.tomorrow .auto-label:hover .auto-validate ~ .checkmark {
  background-color: #f1f1f1;
  box-shadow: 0 0 0 0.4px #d4d4d4;
}
.tomorrow .auto-label .auto-validate:checked ~ .checkmark {
  background-color: #f1f1f1;
  box-shadow: 0 0 0 0.4px #d4d4d4;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.auto-label .auto-validate:checked ~ .checkmark:after {
  display: block;
}

.auto-label .checkmark:after {
  left: 10px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid #e2b714;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dracula .auto-label .checkmark:after {
  border: solid #ffca28;
  border-width: 0 3px 3px 0;
}
.synth-wave .auto-label .checkmark:after {
  border: solid #eedfa8;
  /* shadow to be implemented */
  /* box-shadow: 0px 0px 3px #e2b714; */
  border-width: 0 3px 3px 0;
}
.light-mode .auto-label .checkmark:after {
  border: solid #b58900;
  border-width: 0 3px 3px 0;
}
.horizon .auto-label .checkmark:after {
  border: solid #fa577f;
  border-width: 0 3px 3px 0;
}
.tomorrow .auto-label .checkmark:after {
  border: solid #cd9f00;
  border-width: 0 3px 3px 0;
}
.button-3d-disabled {
  border: solid 1px #e3e3e3;
  background: #e3e3e3;
  color: #928983;
  cursor: initial;

  -webkit-box-shadow: 0px 6px 0px #928983;
  -moz-box-shadow: 0px 6px 0px #928983;
  box-shadow: 0px 6px 0px #928983;
}

.button-3d-valid {
  border: solid 1px #96b497;
  background: #4caf50;

  -webkit-box-shadow: 0px 6px 0px #3c8b3e;
  -moz-box-shadow: 0px 6px 0px #3c8b3e;
  box-shadow: 0px 6px 0px #3c8b3e;
}

.button-3d-valid:active {
  -webkit-box-shadow: 0px 2px 0px #3c8b3e;
  -moz-box-shadow: 0px 2px 0px #3c8b3e;
  box-shadow: 0px 2px 0px #3c8b3e;
}
.button-3d-invalid {
  border: solid 1px #fd6459;
  background: #f44336;

  -webkit-box-shadow: 0px 6px 0px #d84237;
  -moz-box-shadow: 0px 6px 0px #d84237;
  box-shadow: 0px 6px 0px #d84237;
}

.button-3d-invalid:active {
  -webkit-box-shadow: 0px 2px 0px #d84237;
  -moz-box-shadow: 0px 2px 0px #d84237;
  box-shadow: 0px 2px 0px #d84237;
}

.styled-text-box {
  font-family: "Roboto Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  outline: none;
  /* resize: none; */
  cursor: text;
  padding: 5px 10px;
  line-height: 1.5rem;
  font-size: 15px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  font-weight: 500;
}

.styled-text-box::-webkit-scrollbar {
  width: 7px;
}
.styled-text-box::-webkit-scrollbar-thumb {
  border-radius: 100px;
}
.styled-text-box::-webkit-scrollbar-track {
  border-radius: 100px;
}

.styled-text-box:focus {
  outline: none;
}

/* Light Text Area */

.styled-text-box.light-hacker {
  background: #e8e8e8;
  color: #149414;

  scrollbar-color: #149414 #e8e8e8;
  scrollbar-width: 7px;
}
.styled-text-box.light-hacker::-webkit-scrollbar-thumb {
  background: #149414;
}

.styled-text-box.light {
  background: #e8e8e8;
  color: #616161;

  scrollbar-color: #616161 #e8e8e8;
  scrollbar-width: 7px;
}

.styled-text-box.light::-webkit-scrollbar-track {
  background: #e8e8e8;
}

.styled-text-box.light::-webkit-scrollbar-thumb {
  background: #616161;
}

/* Dark Monkey Type Text Area */

.styled-text-box.dark {
  background: #323437;
  color: #e2b714;
  scrollbar-color: #e2b714 #323437;
  scrollbar-width: 7px;
}

.styled-text-box.dark::-webkit-scrollbar-track {
  background: #323437;
}
.styled-text-box.dark::-webkit-scrollbar-thumb {
  background: #e2b714;
}
.styled-text-box.dark::placeholder {
  color: #e2b514b7;
  opacity: 1;
}

.light-mode .styled-text-box.dark::-webkit-scrollbar-thumb {
  background: #b58800d0;
}
.light-mode .styled-text-box.dark::placeholder {
  color: #b58900d0;
  opacity: 1;
}
/* Dark Hacker Text Area */
.styled-text-box.dark-hacker {
  background: #191919;
  color: #20c20e;
}

.styled-text-box.dark-hacker::-webkit-scrollbar-thumb {
  background: #20c20e;
}
.styled-text-box.dark-hacker::-webkit-scrollbar-track {
  background: #191919;
}
.styled-text-box.dark-hacker::placeholder {
  color: #20c20eb7;
  opacity: 1;
}
.error-messsage-container {
  height: 100%;
}
.error-message {
  text-align: center;
  color: red;
}
.success-message {
  text-align: center;
  color: rgb(0, 185, 0);
}
.custom-editor {
  text-align: left;
  width: 50rem;
}
.light-mode .custom-editor {
  box-shadow: 0 0 0 0.4px #eee8d5;
  border-radius: 4px;
}

@media only screen and (min-width: 1600px) {
  .custom-editor {
    width: 60rem;
  }
}
@media only screen and (max-width: 1200px) {
  .custom-editor {
    width: 35rem;
  }
  table {
    margin-top: 100rem;
    margin-bottom: 100rem;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 85px;
  height: 37px;
  margin-left: 0px;
  margin-bottom: 7px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border: solid 1px #dbdada;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.data-slider:checked + .slider {
  background-color: #28a745;
  border: 1px solid #5cb671;
  -webkit-box-shadow: 0px 6px 0px #17742c;
  -moz-box-shadow: 0px 6px 0px #17742c;
  box-shadow: 0px 6px 0px #17742c;
}
.data-slider + .slider::before {
  background-color: #f0f0f0;
}
.data-slider:checked + .slider::before {
  background-color: #d4ecd9;
}

.data-slider:checked + .slider:before {
  -webkit-transform: translateX(48px);
  -ms-transform: translateX(48px);
  transform: translateX(48px);
}
.slider.custom {
  border-radius: 6px;
}

.slider.custom:before {
  border-radius: 5px;
}

.reformat-input {
  height: 27px;
  width: 27px;
  border-radius: 3px;
  background-color: #222426;
  color: #e2b714;
  border: none;
  font-size: 1rem;
  font-weight: bolder;
}
.light-mode .reformat-input {
  color: #b58900;
  background-color: #fdf6e3;
  box-shadow: 0 0 0 0.4px #eee8d5;
}

.dracula .reformat-input {
  background-color: #21222c;
  color: #ffca28;
}
.synth-wave .reformat-input {
  background: #2b213a;
  color: #eedfa8;
  text-shadow: 0px 0px 3px #e2b714;
}
.horizon .reformat-input {
  color: #fa577f;
  background-color: #fce5df;
  box-shadow: 0 0 0 0.4px #ffd5cb;
}
.tomorrow .reformat-input {
  color: #cd9f00;
  background-color: #f1f1f1;
  box-shadow: 0 0 0 0.4px #d4d4d4;
}

.reformat-input:focus {
  outline: none;
}
.reformat-text {
  position: absolute;
  margin-top: 2px;
  width: 5.2rem;
  margin-left: 7px;
}
.reformat-container {
  position: absolute;
  left: 100%;
  margin-bottom: 14px;
  margin-left: 14px;
}
.space-error-message {
  position: absolute;
  color: red;
  width: 35px;
  margin: 0;
  font-size: 0.8rem;
}
.copy-message-text {
  position: absolute;
  margin: 0;
  left: 100%;
  margin-top: 8px;
  margin-left: 14px;
  color: #28a745;
}

.example-text {
  font-weight: bolder;
  margin-top: 0;
  margin-bottom: 9px;
}

.data-type-container {
  margin-bottom: 9px;
  position: relative;
}
.data-type-text-container {
  position: absolute;
  right: 100%;
  top: 0.5rem;
  margin-right: 1rem;
  text-align: right;
  width: 9rem;
}
.data-type-text {
  margin: 0;
  width: 100%;
}

.example-container {
  text-align: right;
}
.copy-button-wrapper {
  position: absolute;
  top: 7px;
  right: 10px;
}
.copy-button {
  cursor: pointer;
  z-index: 5;
  background-color: #222426;
  padding: 7px;
}
.copy-button.fa-copy {
  color: #e2b714;
}
.dracula .copy-button.fa-copy {
  color: #ffca28;
}
.synth-wave .copy-button.fa-copy {
  color: #eedfa8;
}
.light-mode .copy-button.fa-copy {
  color: #b58900;
}
.horizon .copy-button.fa-copy {
  color: #fe6a8e;
}
.tomorrow .copy-button.fa-copy {
  color: #cd9f00;
}

.copy-button.fa-clipboard-check {
  color: #28a745;
}
.light-mode .copy-button {
  background-color: #fdf6e3;
}
.dracula .copy-button {
  background-color: #343746;
}
.synth-wave .copy-button {
  background-color: #2f2442;
}
.horizon .copy-button {
  background-color: #fce5df;
}
.tomorrow .copy-button {
  background-color: #f1f1f1;
}

.CodeMirror-vscrollbar::-webkit-scrollbar {
  width: 5px;
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
  -ms-overflow-style: 6px;
  scrollbar-width: 6px;
}

.CodeMirror-hscrollbar::-webkit-scrollbar {
  height: 5px;
}

/* Handle */

.CodeMirror:hover .CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.CodeMirror:hover .CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background: #e2b71488;
  border-radius: 100px;
}

.light-mode .CodeMirror:hover .CodeMirror-vscrollbar::-webkit-scrollbar-thumb,
.light-mode .CodeMirror:hover .CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background: #b5880088;
}

.CodeMirror .CodeMirror-vscrollbar,
.CodeMirror .CodeMirror-hscrollbar {
  scrollbar-width: none;
}
.CodeMirror:hover .CodeMirror-vscrollbar,
.CodeMirror:hover .CodeMirror-hscrollbar {
  scrollbar-color: #e2b71488 transparent;
  scrollbar-width: thin;
}
.light-mode .CodeMirror:hover .CodeMirror-vscrollbar,
.light-mode .CodeMirror:hover .CodeMirror-hscrollbar {
  scrollbar-color: #b5880060 transparent;
  scrollbar-width: thin;
}

.custom-editor .CodeMirror-scrollbar-filler {
  background-color: transparent;
}

.CodeMirror-placeholder {
  font-style: italic;
  color: rgba(255, 255, 255, 0.274) !important;
}
.light-mode .CodeMirror-placeholder {
  font-style: italic;
  color: #b5880088 !important;
}
.reformat-input::-webkit-outer-spin-button,
.reformat-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  background: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z' fill='%23e2b714'/></svg>")
    no-repeat 50% 50%;
  width: 4px;
  height: 12px;
  padding: 4px;
  position: relative;
  right: 0px;
  border-radius: 3px;
  cursor: pointer;
}
.light-mode .reformat-input::-webkit-outer-spin-button,
.light-mode .reformat-input::-webkit-inner-spin-button {
  background: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z' fill='%23b58900'/></svg>")
    no-repeat 50% 50%;
}

.reformat-input {
  -moz-appearance: textfield;
}

.header-text-container {
  width: 50rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1600px) {
  .header-text-container {
    width: 60rem;
  }
}

@media only screen and (max-width: 1200px) {
  .header-text-container {
    width: 35rem;
  }
}

@media only screen and (max-width: 779px) {
  .header-text-container {
    width: 48.6rem;
  }
  .header-text {
    transform: translateX(12.5%);
  }
}

.theme-button {
  position: absolute;
  right: -5.563rem;
  top: 0.5rem;
}
.theme-slider + .slider {
  background-color: #dcad35;
  border: solid 1px #ae8a2d;
  /* -webkit-box-shadow: 0px 6px 0px #ae8a2d;
  -moz-box-shadow: 0px 6px 0px #ae8a2d;
  box-shadow: 0px 6px 0px #ae8a2d; */
}

.theme-slider:checked + .slider {
  background-color: #666666;
  border: 1px solid #222426;
  /* -webkit-box-shadow: 0px 6px 0px #222426;
  -moz-box-shadow: 0px 6px 0px #222426;
  box-shadow: 0px 6px 0px #222426; */
}
.theme-slider + .slider::before {
  background-color: #f0f0f0;
  box-shadow: 0px 3px 0px #ae8a2d;
}
.theme-slider:checked + .slider::before {
  background-color: #fdf4e0;
}

.theme-slider:checked + .slider:before {
  -webkit-transform: translateX(48px);
  -ms-transform: translateX(48px);
  transform: translateX(48px);
  box-shadow: 0px 3px 0px #222426;
}

.theme-label {
  position: absolute;
  left: 0;
  top: -2.75rem;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0.5rem;
  width: max-content;
  text-align: left;
}

.navbar {
  background: #666666;
  justify-content: center;
  align-items: center;
  height: 74.84px;
  margin-bottom: 21.24px;
}

.navbar-bg-light {
  background: #fdf4e0;
}
.navbar-bg-dark {
  background: #666666;
}

.nav-item-dark {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;

  color: #e4e4e4;
}

.nav-item-selected-dark {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #ffd64e;
}

.nav-item-light {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;

  color: #2c2e30;
}

.nav-item-selected-light {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #ae7c1c;
}

.mr-3 {
  margin-right: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bar-parent-dark {
  position: relative;
}
.bar-parent-dark::before {
  position: absolute;
  content: "";
  background-color: #ffd64e;
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 29%;
}

.bar-parent-light {
  position: relative;
}
.bar-parent-light::before {
  position: absolute;
  content: "";
  background-color: #ae7c1c;
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 29%;
}

.json-one,
.json-two {
  width: 121px;
  text-align: center;
}

.navbar {
  background: #666666;
  justify-content: center;
  align-items: center;
  height: 74.84px;
  margin-bottom: 21.24px;
}

.navbar-bg-light {
  background: #fdf4e0;
}
.navbar-bg-dark {
  background: #666666;
}

.nav-item-dark {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;

  color: #e4e4e4;
}

.nav-item-selected-dark {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #ffd64e;
}

.nav-item-light {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;

  color: #2c2e30;
}

.nav-item-selected-light {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #ae7c1c;
}

.mr-3 {
  margin-right: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bar-parent-dark {
  position: relative;
}
.bar-parent-dark::before {
  position: absolute;
  content: "";
  background-color: #ffd64e;
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 29%;
}

.bar-parent-light {
  position: relative;
}
.bar-parent-light::before {
  position: absolute;
  content: "";
  background-color: #ae7c1c;
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 29%;
}

.json-one,
.json-two {
  width: 121px;
  text-align: center;
}

.navbar {
  background: #666666;
  justify-content: center;
  align-items: center;
  height: 74.84px;
  margin-bottom: 21.24px;
}

.navbar-bg-light {
  background: #fdf4e0;
}
.navbar-bg-dark {
  background: #666666;
}

.nav-item-dark {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;

  color: #e4e4e4;
}

.nav-item-selected-dark {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #ffd64e;
}

.nav-item-light {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;

  color: #2c2e30;
}

.nav-item-selected-light {
  font-family: "Space Mono";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  text-align: center;
  color: #ae7c1c;
}

.mr-3 {
  margin-right: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.bar-parent-dark {
  position: relative;
}
.bar-parent-dark::before {
  position: absolute;
  content: "";
  background-color: #ffd64e;
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 29%;
}

.bar-parent-light {
  position: relative;
}
.bar-parent-light::before {
  position: absolute;
  content: "";
  background-color: #ae7c1c;
  width: 48px;
  height: 4px;
  bottom: -15px;
  left: 29%;
}

.json-one,
.json-two {
  width: 121px;
  text-align: center;
}
