body {
  background-color: #323437;
  color: #c0c0c0;
  transition: all 0.35s;
}
body.light-mode {
  background-color: #fefbf3;
  color: #323437;
}
.light-mode {
  transition: all 0.35s;
}
body.dracula {
  background-color: #282a36;
  color: #c0c0c0;
}
.dracula {
  transition: all 0.35s;
}
body.synth-wave {
  background: #20172e;
  color: #fafafa;
}
.synth-wave {
  transition: all 0.35s;
}
body.horizon {
  background: #fdf0ee;
  color: #323437;
}
.horizon {
  transition: all 0.35s;
}
body.tomorrow {
  background: #fafafa;
  color: #4d4d64;
}
.horizon {
  transition: all 0.35s;
}

body.preload {
  transition: none;
}

.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-color: #e7e9eb;
}
a,
a:visited {
  color: #e2b714;
  text-decoration: none;
}
.light-mode a,
.light-mode a:visited {
  color: #b58900;
}
.dracula a,
.dracula a:visited {
  color: #ffca28;
}
.synth-wave a,
.synth-wave a:visited {
  color: #eedfa8;
  text-shadow: 0px 0px 3px #e2b714;
}
.horizon a,
.horizon a:visited {
  color: #fe6a8e;
}
.tomorrow a,
.tomorrow a:visited {
  color: #cd9f00;
}

td {
  vertical-align: top;
  hyphens: auto;
  font-size: 14px;
}
.keys {
  hyphens: none;
}

th {
  text-align: left;
  hyphens: none;
}
.light-mode table {
  border-color: #eee8d5;
}

table {
  margin: auto;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
}

.text-area {
  display: block;
  border: 1px;
  background-color: #e7e9eb;
}
.container {
  margin-left: auto;
  margin-right: auto;
}

.ml-1 {
  margin-left: 1rem;
}
.data-type {
  text-align: left;
  font-weight: normal;
  color: #e2b714;
  font-size: 0.9rem;
  font-style: italic;
}
.light-mode .data-type {
  color: #b58900;
}
.keys {
  font-weight: bold;
}
.index {
  border: none;
}
.text-area-heading {
  text-align: center;
}
.paragraph-text-area {
  text-align: center;
  font-size: 0;
}

.collapse-button {
  font-family: "Space Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  background: green;
  color: white;
}
.fontawesome-button {
  background-color: #28a745;
  height: 22px;
  width: 22px;
  color: #e7e9eb;
  border-radius: 5px;
  border-width: 0px;
  padding: 2px;
  cursor: pointer;
}
.fontawesome-button:focus {
  outline: none;
}
.display-table-content {
  display: none;
}

.button-div {
  margin-bottom: 0.2rem;
}

.horizontal-container > div > .button-div,
.vertical-container > div > .button-div {
  text-align: center;
}
body {
  margin: 0;
  font-family: "Space Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Space Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
.nothing-to-display {
  font-size: normal;
  text-align: center;
  font-style: italic;
}
.toast-container {
  background-color: #3c4043;
  width: 340px;
  display: flex;
  align-items: center;
  padding-inline: 12px;
  padding-block: 16px;
  border-radius: 5px;
  gap: 16px;
  margin: 10px 6px;
  position: relative;
  animation: 0.4s rollout;
  box-shadow: 0px 6px #222426;
  color: #ffffff;
}
.toast-container > .img-wrapper {
  box-shadow: 0px 4px #064d2b;
  border-radius: 50%;
  padding: 6.4px 7px 7.7px 7.4px;
  background-color: #04b763;
  display: inherit;
}
.light-mode .toast-container {
  background-color: #cac9c3;
  color: inherit;
}
.light-mode .cancel-icon {
  fill: #323437;
}
.cancel-icon {
  fill: #ffffff;
}
.toast-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 7.7px;
  margin-right: 11.7px;
}
.toast-btn > button {
  background-color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
}

.shortkey-popover {
  font-size: 12px;
  position: absolute;
  padding-inline: 8px;
  padding-block: 6px;
  background: #535353;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  color: #ecf0f1;
  text-align: center;
  top: 52px;
  left: 7%;
  z-index: 1;
}
.top-x35 {
  top: 35px !important;
}
.shortkey-popover .carrot-sign {
  padding: 7px;
  position: absolute;
  background: inherit;
  top: -5px;
  transform: rotate(45deg);
  left: 38%;
  z-index: 0;
  border-radius: 3px;
}

.shortkey-popover > .code-value {
  display: flex;
  gap: 4px;
}
.shortkey-popover > .code-value > div {
  background: #222426;
  padding: 6px;
  border-radius: 4px;
}

.light-mode .shortkey-popover {
  background: #cac9c3;
  color: #323437;
}

.light-mode .shortkey-popover > .code-value > div {
  background: #f9f8f1;
  color: #323437;
}

.keymap-popover {
  background: #e5e4db;
  color: #323437;
  position: absolute;
  border-radius: 4px;
  z-index: 9999;
}
.keymap-top {
  top: -31px;
  left: 27px;
}
.keymap-bottom {
  bottom: -21px;
  left: 27px;
}
.keymap-right {
  top: 15%;
  right: -37px;
}
.keymap-popover > .carrot-sign {
  padding: 4px;
  position: absolute;
  background: inherit;
  transform: rotate(45deg);
  border-radius: 1px;
}
.keymap-popover > .carrot-top {
  top: 19px;
  left: 11px;
}
.keymap-popover > .carrot-bottom {
  bottom: 19px;
  left: 10.5px;
}
.keymap-popover > .carrot-right {
  top: 9.5px;
  left: -3px;
  padding: 3px;
}
.checkbox-keymap {
  bottom: -33px;
  left: 1px;
}
.show-keymap-button-wrapper {
  position: absolute;
  right: 9px;
  bottom: 4px;
  z-index: 999;
}

.show-keymap-button.fa-keyboard {
  color: #e2b714;
  cursor: pointer;
}
.dracula .show-keymap-button.fa-keyboard {
  color: #ffca28;
  cursor: pointer;
}
.synth-wave .show-keymap-button.fa-keyboard {
  color: #eedfa8;
}
.light-mode .show-keymap-button.fa-keyboard {
  color: #b58900;
  cursor: pointer;
}
.horizon .show-keymap-button.fa-keyboard {
  color: #fe6a8e;
}
.tomorrow .show-keymap-button.fa-keyboard {
  color: #cd9f00;
}

.visible {
  display: block !important;
}
.backdrop {
  position: absolute;
  z-index: 1;
  background: rgb(19, 20, 21, 70%);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
}
.light-mode .backdrop {
  background: rgb(19, 20, 21, 50%);
}
.keyboard-icon-popover {
  position: absolute;
  z-index: 999;
  background: #535353;
  width: max-content;
  border-radius: 4px;
  padding: 8px 6px;
  font-size: 14px;
  right: -10px;
  top: 26px;
  line-height: 10px;
}

.keyboard-icon-carrot {
  position: absolute;
  padding: 10px;
  position: absolute;
  padding: 4px;
  top: -8%;
  right: 15px;
  background: inherit;
  transform: rotate(45deg);
}

.light-mode .keyboard-icon-popover {
  background: #f4f2e3;
  color: #323437;
}
.theme-btn-list {
  position: absolute;
  display: flex;
  gap: 16px;
  top: 0.25rem;
  left: 5rem;
  margin-left: 1.5rem;
}
.theme-btn {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  position: relative;
}
.theme-btn > span {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
  position: absolute;
  top: -8px;
  left: -1px;
  transition: all 0.75s;
}

.theme-btn.selected > span {
  pointer-events: none;
  cursor: default;
  transform: translateY(3px);
  transition: all 0.75s;
}

.btn-monkey > span {
  border-color: #1e2022;
  background: #4a4d51;
}

.btn-monkey {
  background: #1e2022;
}
.btn-dracula > span {
  background-color: #707eaa;
  border-color: #384058;
}
.btn-dracula {
  background: #384058;
}
.btn-synth-wave > span {
  background-color: #5d4c75;
  border-color: #231b30;
}
.btn-synth-wave {
  background: #231b30;
}

.btn-solarize > span {
  background-color: #fff6dd;
  border-color: #b2a378;
}
.btn-solarize {
  background: #b2a378;
}

.btn-horizon > span {
  background-color: #ffb7c9;
  border-color: #ce748a;
}
.btn-horizon {
  background: #ce748a;
}

.btn-tomorrow > span {
  background-color: #e8e8e8;
  border-color: #a1a1a1;
}
.btn-tomorrow {
  background: #a1a1a1;
}

/* Theme Button Svg */

/* Monkey Button */

.btn-monkey .monkey-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 7px;
  left: 7.5px;
  gap: 2px;
  align-items: center;
}
.btn-monkey .monkey-eyes {
  height: 10px;
  display: flex;
  gap: 7.5px;
  transition: height 0.6s;
  height: 10px;
  align-items: center;
}
.btn-monkey .monkey-eyes > div {
  height: 10px;
  width: 7.5px;
  border-radius: 1000px;
  background: #ed640a;
  transition: height 0.6s;
}
.btn-monkey:hover .monkey-eyes > div {
  height: 1px;
}

/* Dracula Button */

.dracula-svg > .moon {
  position: absolute;
  top: -10.5px;
  left: 8px;
  transition: all 0.5s;
}
.dracula-svg > .cloud {
  position: absolute;
  top: -9px;
  left: 8px;
  transition: all 0.5s;
}

.btn-dracula:hover .moon {
  transform: translate(-2.5px, -2.5px);
  transition: all 0.5s;
}
.btn-dracula:hover .cloud {
  transform: translate(2.5px, 2.5px);
  transition: all 0.5s;
}

/* Synth Wave Button */

.btn-synth-wave .bg-star {
  position: absolute;
  display: flex;
  width: 10px;
  height: 10px;
  top: 8.5px;
  left: 9.5px;
  transform: scale(1.2);
  transition: all 0.5s;
}
.btn-synth-wave .md-star {
  position: absolute;
  display: flex;
  width: 6.5px;
  height: 6.5px;
  top: 21.5px;
  left: 14.2px;
  transition: all 0.5s;
}
.btn-synth-wave .sm-star {
  position: absolute;
  display: flex;
  width: 5px;
  height: 5px;
  top: 14.5px;
  left: 21.5px;
  transition: all 0.5s;
}

.btn-synth-wave:hover .bg-star {
  transform: rotate(90deg) scale(1.7);
  transition: all 0.5s;
  left: 9px;
}
.btn-synth-wave:hover .bg-star > img,
.btn-synth-wave:hover .md-star > img,
.btn-synth-wave:hover .sm-star > img {
  filter: drop-shadow(0px 0px 1px #e2b714);
}
.btn-synth-wave:hover .md-star {
  transform: rotate(90deg) scale(1.4);
  top: 24.5px;
  left: 15.2px;
  transition: all 0.5s;
}
.btn-synth-wave:hover .sm-star {
  transform: rotate(45deg) scale(1.5);
  transition: all 0.5s;
  top: 15.5px;
  left: 24px;
}

/* Solarize Button */

.solarize-svg > div {
  display: flex;
  transition: all 0.5s;
  position: absolute;
}
.btn-solarize .sun-centre {
  left: 27%;
  top: 26%;
}

.btn-solarize .sun-ray {
  left: 7%;
  top: 6%;
  transform: rotate(15deg) scale(0.75);
}

.btn-solarize:hover .sun-centre {
  transform: rotate(45deg);
  transition: all 0.5s;
}

.btn-solarize:hover .sun-ray {
  transform: rotate(70deg) scale(1);
  transition: all 0.5s;
}

/* Horizon Button */

.btn-horizon .horizon-svg > div {
  position: absolute;
  display: flex;
}
.btn-horizon .sun-fog {
  top: 4px;
  left: 4px;
}
.btn-horizon .sun-shine-1 {
  bottom: 13px;
  left: 7px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1.5s, opacity 1s;
}
.btn-horizon .sun-shine-2 {
  bottom: 10px;
  left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 1.5s;
}
.btn-horizon .sun-shine-3 {
  bottom: 6px;
  left: 13px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 2s;
}
.btn-horizon:hover .sun-shine-1 {
  visibility: visible;
  opacity: 1;
}
.btn-horizon:hover .sun-shine-2 {
  visibility: visible;
  opacity: 1;
}
.btn-horizon:hover .sun-shine-3 {
  visibility: visible;
  opacity: 1;
}

/* Tomorrow Button */
.tomorrow-svg > div {
  display: flex;
  transition: all 0.5s;
}
.btn-tomorrow .star {
  position: absolute;
  top: 25%;
  left: 25%;
  transition: all 0.5s;
}
.btn-tomorrow .shooting-star {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 35%;
  left: 13%;
}
.btn-tomorrow:hover .star {
  transform: rotate(45deg) scale(0.6);
  transition: all 0.5s;
  top: 7.5px;
  right: -13px;
}
.btn-tomorrow:hover .shooting-star {
  visibility: visible;
  opacity: 1;
  transform: translate(1.5px, 1.5px);
}

/* Keyboard Icon */

.keyboard-icon {
  width: 31px;
  height: 25px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.copy-icon {
  width: 31px;
  height: 25px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.copy-icon > img {
  width: 20px;
}
.synth-wave .copy-icon > img {
  width: 30px;
}
.copy-icon-popover {
  position: absolute;
  width: max-content;
  right: 45px;
  font-size: 14px;
  top: 9px;
  background: #323437ee;
  padding: 3px 11px;
  border-radius: 4px;
}
.copy-icon-carrot {
  background: inherit;
  height: 6px;
  width: 6px;
  transform: rotate(45deg);
  position: absolute;
  top: 9px;
  right: -2.5px;
  border-radius: 1px;
}

.dracula .copy-icon-popover {
  color: #ffca28 !important;
  background-color: #282a36f7;
}
.synth-wave .copy-icon-popover {
  color: #eedfa8 !important;
  text-shadow: 0px 0px 3px #e2b714;
  background-color: #20172ef1;
}
.light-mode .copy-icon-popover {
  color: #b58900 !important;
  background-color: #f1e6cad5;
}
.horizon .copy-icon-popover {
  color: #fe6a8e !important;
  background-color: #efcdc8c7;
}
.tomorrow .copy-icon-popover {
  color: #cd9f00 !important;
  background-color: #e7e6e6e5;
}

/* Animations */

@keyframes rollout {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
