.cm-s-solarized-light {
  background-color: #fdf6e3;
  color: #657b83;
}
.cm-s-solarized-light .emphasis {
  font-weight: bold;
}
.cm-s-solarized-light .dotted {
  border-bottom: 1px dotted #cb4b16;
}
.cm-s-solarized-light .CodeMirror-gutter {
  background-color: #eee8d5;
  border-right: 1px solid #eee8d5;
}
.cm-s-solarized-light .CodeMirror-gutter .CodeMirror-gutter-text {
  color: #fff;
}
.cm-s-solarized-light .CodeMirror-cursor {
  border-left-color: #b58900 !important;
}

.cm-s-solarized-light .CodeMirror-guttermarker,
.cm-s-solarized-light .CodeMirror-guttermarker-subtle,
.cm-s-solarized-light .CodeMirror-linenumber {
  color: #b58900;
}

.cm-s-solarized-light .CodeMirror-matchingbracket {
  color: #002b36;
  background-color: #eee8d5;
  box-shadow: 0 0 10px #eee8d5;
  font-weight: bold;
}
.cm-s-solarized-light .CodeMirror-nonmatchingbracket {
  color: #002b36;
  background-color: #eee8d5;
  box-shadow: 0 0 10px #eee8d5;
  font-weight: bold;
  color: #dc322f;
  border-bottom: 1px dotted #cb4b16;
}
.cm-s-solarized-light span.cm-keyword {
  color: #657b83;
  font-weight: bold;
}
.cm-s-solarized-light span.cm-atom {
  color: #2aa198;
}
.cm-s-solarized-light span.cm-number {
  color: #586e75;
}
.cm-s-solarized-light span.cm-def {
  color: #268bd2;
}
.cm-s-solarized-light span.cm-variable {
  color: #cb4b16;
}
.cm-s-solarized-light span.cm-variable-2 {
  color: #cb4b16;
}
.cm-s-solarized-light span.cm-variable-3 {
  color: #cb4b16;
}
.cm-s-solarized-light span.cm-comment {
  color: #93a1a1;
}
.cm-s-solarized-light span.cm-property {
  color: #b58900;
}
.cm-s-solarized-light .cm-string.cm-property {
  color: #b58900;
}
.cm-s-solarized-light span.cm-operator {
  color: #657b83;
}
.cm-s-solarized-light span.cm-string {
  color: #6c71c4;
}
.cm-s-solarized-light span.cm-error {
  font-weight: bold;
  border-bottom: 1px dotted #cb4b16;
}
.cm-s-solarized-light span.cm-bracket {
  color: #cb4b16;
}
.cm-s-solarized-light span.cm-tag {
  color: #657b83;
}
.cm-s-solarized-light span.cm-attribute {
  color: #586e75;
  font-weight: bold;
}
.cm-s-solarized-light span.cm-meta {
  color: #268bd2;
}
