@import 'codemirror/theme/material.css';
body {
  margin: 0;
  font-family: 'Space Mono', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Space Mono', source-code-pro, Menlo, Monaco, Consolas,
    'Courier New', monospace;
}
button:disabled,
input:disabled {
  opacity: 0.5 !important;
  cursor: default;
}
