.cm-s-monkeytype.CodeMirror {
  background-color: #222426;
  color: #d1d0c5;
}

.cm-s-monkeytype .CodeMirror-gutters {
  background: #222325;
  color: #e2b714;
  border: none;
}

.cm-s-monkeytype .CodeMirror-guttermarker,
.cm-s-monkeytype .CodeMirror-guttermarker-subtle,
.cm-s-monkeytype .CodeMirror-linenumber {
  color: #e2b714;
}

.cm-s-monkeytype .CodeMirror-cursor {
  border-left: 1px solid #e2b714;
}

.cm-s-monkeytype div.CodeMirror-selected {
  background: rgba(175, 175, 175, 0.2);
}

.cm-s-monkeytype.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(175, 175, 175, 0.2);
}

.cm-s-monkeytype .CodeMirror-line::selection,
.cm-s-monkeytype .CodeMirror-line > span::selection,
.cm-s-monkeytype .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-monkeytype .CodeMirror-line::-moz-selection,
.cm-s-monkeytype .CodeMirror-line > span::-moz-selection,
.cm-s-monkeytype .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-monkeytype .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}

.cm-s-monkeytype .cm-keyword {
  color: #c792ea;
}

.cm-s-monkeytype .cm-operator {
  color: #89ddff;
}

.cm-s-monkeytype .cm-variable-2 {
  color: #eeffff;
}

.cm-s-monkeytype .cm-variable-3,
.cm-s-monkeytype .cm-type {
  color: #ca4754;
}

.cm-s-monkeytype .cm-builtin {
  color: #e2b714;
}

.cm-s-monkeytype .cm-atom {
  color: #f78c6c;
}

.cm-s-monkeytype .cm-number {
  color: #ff5370;
}

.cm-s-monkeytype .cm-def {
  color: #82aaff;
}

.cm-s-monkeytype .cm-string {
  color: #e2b714;
}

.cm-s-monkeytype .cm-string-2 {
  color: #ca4754;
}

.cm-s-monkeytype .cm-comment {
  color: #646669;
}

.cm-s-monkeytype .cm-variable {
  color: #ca4754;
}

.cm-s-monkeytype .cm-tag {
  color: #ff5370;
}

.cm-s-monkeytype .cm-meta {
  color: #e2b714;
}

.cm-s-monkeytype .cm-attribute {
  color: #ca4754;
}

.cm-s-monkeytype .cm-property {
  color: #ca4754;
}

.cm-s-monkeytype .cm-qualifier {
  color: #e2b514b7;
}

.cm-s-monkeytype .cm-variable-3,
.cm-s-monkeytype .cm-type {
  color: #e2b514b7;
}

.cm-s-monkeytype .cm-error {
  color: rgba(255, 255, 255, 1);
  background-color: #ff5370;
}

.cm-s-monkeytype .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
